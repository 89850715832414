import { convertDisplayPriceToNumber } from '../../../../utils/parse-display-price';

export const getRecipe = ({ recipes }) => {
  const recipe = recipes[recipes.id] || {};
  return {
    ...recipes,
    isShoppable: !recipe.pimsError && recipes.isShoppable,
    metaDescription: recipes.metaDescription || recipes.description?.plainText || '',
    products: recipe.products,
    rating: recipe.rating,
    shoppableProducts: recipe.shoppable,
  };
};

export const getRecipeById =
  id =>
  ({ recipes }) => {
    return recipes?.[id];
  };

export const getLineNumbers = state =>
  (state.recipes[state.recipes.id]?.products || []).map(
    ingredient => ingredient.products[0]?.lineNumber,
  );

export const getShoppableProducts = ({ recipes }, recipeId) => {
  const recipe = recipes[recipeId || recipes.id];

  return recipe ? recipe.shoppableProducts : null;
};

export const getMultipleRecipesShoppableProducts = (state, recipeIds) =>
  recipeIds.reduce((shoppableProducts, recipeId) => {
    const recipe = state.recipes[recipeId];
    return recipe ? shoppableProducts.concat(recipe.shoppableProducts) : shoppableProducts;
  }, []);

export const getNumberOfSelectedShoppableProducts = ({ recipes }, recipeId) => {
  const recipe = recipes[recipeId || recipes.id] || {};
  const { shoppableProducts } = recipe;

  return shoppableProducts?.length
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;
        return (
          total +
          storeCupboard.filter(ingredient => ingredient.amountSelected > 0).length +
          nonStoreCupboard.filter(ingredient => ingredient.amountSelected > 0).length
        );
      }, 0)
    : 0;
};

export const getNumberOfShoppableProducts = ({ recipes }, recipeId) => {
  const recipe = recipes[recipeId || recipes.id] || {};
  const { shoppableProducts } = recipe;

  return shoppableProducts?.length > 0
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;
        return total + storeCupboard.length + nonStoreCupboard.length;
      }, 0)
    : 0;
};

export const getNumberOfSelectedShoppableProductsForMultipleRecipes = (state, recipeIds) =>
  recipeIds.reduce(
    (total, recipeId) => total + getNumberOfSelectedShoppableProducts(state, recipeId),
    0,
  );

export const getNumberOfShoppableProductsForMultipleRecipes = (state, recipeIds) =>
  recipeIds.reduce((total, recipeId) => total + getNumberOfShoppableProducts(state, recipeId), 0);

export const getTotalPriceOfSelectedShoppableProducts = ({ recipes }, recipeId) => {
  const recipe = recipes[recipeId || recipes.id] || {};
  const { shoppableProducts } = recipe;

  return shoppableProducts?.length
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;
        return (
          total +
          storeCupboard.reduce((storeTotal, ingredient) => {
            return storeTotal + (convertDisplayPriceToNumber(ingredient?.totalPrice) || 0);
          }, 0) +
          nonStoreCupboard.reduce((nonStoreTotal, ingredient) => {
            return nonStoreTotal + (convertDisplayPriceToNumber(ingredient?.totalPrice) || 0);
          }, 0)
        );
      }, 0)
    : 0;
};

export const getRecipesTotalPriceOfSelectedShoppableProducts = (state, recipeIds) =>
  recipeIds.reduce(
    (total, recipeId) => total + getTotalPriceOfSelectedShoppableProducts(state, recipeId),
    0,
  );

export const getNumberOfRecipesWithShoppableProducts = (state, recipeIds) =>
  recipeIds.reduce(
    (total, recipeId) => (getNumberOfShoppableProducts(state, recipeId) > 0 ? total + 1 : total),
    0,
  );
