import { MONETATE_DEFAULT_VARIANT } from './monetate';

export interface MonetateVariant extends Record<string, string> {
  default: typeof MONETATE_DEFAULT_VARIANT;
}

export interface MonetateExperiment {
  experiment: string;
  variant: MonetateVariant;
}

export const URGENCY_BANNER: MonetateExperiment = {
  experiment: 'urgencyBanner',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    danger: 'danger',
    warning: 'warning',
    info: 'info',
  },
};

export const NAV_LINKS_TRENDING: MonetateExperiment = {
  experiment: 'navLinksTrending',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showTrending: 'showTrending',
  },
};

export const BOOST_CATS: MonetateExperiment = {
  experiment: 'boostCats',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    boost: 'boost',
  },
  // provides following payload:
  //
  // {
  //    slotFrom: "2022-05-20",
  //    slotTo: "2022-05-27",
  //    categories = ["12345", "4567"]
  //    excludedBranchIds = ["1","2","3"]
  // }
};

export const POD_SLIDEDOWN_ADVERT_RECIPES: MonetateExperiment = {
  experiment: 'podSlidedownAdvertRecipes',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showAdvertImage: 'showAdvertImage',
    showAdvertIcon: 'showAdvertIcon',
  },
  // provides following payload:
  //
  // {
  //    productIds: [],
  //    imageUrl?: "",
  //    iconName?: "",
  //    text: "",
  //    url: "",
  // }
};

export const POD_SLIDEDOWN_ADVERT_RELATED: MonetateExperiment = {
  experiment: 'podSlidedownAdvertRelated',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showAdvertImage: 'showAdvertImage',
    showAdvertLogo: 'showAdvertLogo',
  },
  // provides following payload:
  //
  // {
  //    productIds: [],
  //    imageUrl?: "",
  //    logoUrl?: "",
  //    text: "",
  //    url: "",
  // }
};

export const POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH: MonetateExperiment = {
  experiment: 'podSlidedownAdvertGoesWellWith',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showAdvertImage: 'showAdvertImage',
    showAdvertIcon: 'showAdvertIcon',
  },
  // provides following payload:
  //
  // {
  //    productIds: [],
  //    imageUrl?: "",
  //    iconName?: "",
  //    text: "",
  //    url: "",
  // }
};

export const POD_SLIDEDOWN_MULTIBUY_NUDGE: MonetateExperiment = {
  experiment: 'podSlidedownMultibuyNudge',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showLinkBuild: 'showLinkBuild',
    showLinkComplete: 'showLinkComplete',
    showLinkExplore: 'showLinkExplore',
  },
};

export const SHOW_SIMILAR: MonetateExperiment = {
  experiment: 'showSimilarLink',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showLink: 'showLink',
  },
};

export const SHOW_MYJL_REWARDS: MonetateExperiment = {
  experiment: 'showMyJohnLewisRewards',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showMyJLRewards: 'showMyJLRewards',
  },
};

export const SHOW_PRODUCT_TAGS: MonetateExperiment = {
  experiment: 'productTags',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showTags: 'showTags',
  },
};

export const SHOW_MARKETING_BADGES: MonetateExperiment = {
  experiment: 'showMarketingBadges',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showBadges: 'showBadges',
  },
};

export const SPONSORED_FAVOURITES: MonetateExperiment = {
  experiment: 'sponsoredFavourites',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showSponsored: 'showSponsored',
  },
};

export const CHECKOUT_SAVE_CARD_COPY: MonetateExperiment = {
  experiment: 'checkoutSaveCardCopyMay',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    newText: 'newText',
  },
};

export const PDP_RECOMMENDATIONS: MonetateExperiment = {
  experiment: 'PDPRecommendations',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    withRecommendations: 'withRecommendations',
  },
};

export const POD_VIEW_ALTERNATIVES: MonetateExperiment = {
  experiment: 'podViewAlternatives',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    viewSimilar: 'viewSimilar',
  },
};

export const POD_WAS_PRICE: MonetateExperiment = {
  experiment: 'podWasPrice',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showStrikethrough: 'showStrikethrough',
  },
};

export const TROLLEY_PROMO_BADGE: MonetateExperiment = {
  experiment: 'trolleyPromoBadgeV2',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    displayPromoBadgingV2: 'displayPromoBadgingV2',
  },
};

export const SWAP_UNAVAILABLE_TROLLEY_ITEMS: MonetateExperiment = {
  experiment: 'swapUnavailableTrolleyItems',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    displaySwap: 'displaySwap',
  },
};

export const EASTER_MODAL_EXPERIMENT: MonetateExperiment = {
  experiment: 'easterModal',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    displayEasterModal: 'displayEasterModal',
  },
};

export const HOME_PAGE_HEADER: MonetateExperiment = {
  experiment: 'homePageHeader',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    displayGroceryHeader: 'displayGroceryHeader',
  },
};

export const EOS_MULTIPLE_IMAGERY: MonetateExperiment = {
  experiment: 'EoS_multipleImages',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    variantA: 'variantA',
  },
};

export const EOS_HERO_IMAGERY: MonetateExperiment = {
  experiment: 'EoS_heroImages',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    variantB: 'variantB',
  },
};

export const CATEGORY_NAVIGATION: MonetateExperiment = {
  experiment: 'EoS_CategoryNavigation',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showLinks: 'showLinks',
    showCarousel: 'showCarousel',
    showPills: 'showPills',
  },
};

export const SERVICE_SELECTION_COLLECTION_ALTERNATIVE: MonetateExperiment = {
  experiment: 'service_selection_collection_alternative',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    checkbox: 'checkbox',
  },
};

export const BUILD_OFFER_DETAILS_AEM_IMAGES: MonetateExperiment = {
  experiment: 'build_offerDetailsAEMImages',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    noPreload: 'noPreload',
  },
};

export const BUILD_VIEW_ALTERNATIVES: MonetateExperiment = {
  experiment: 'build_viewAlternatives',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    toggleModal: 'toggleModal',
  },
};

export const EOS_SEARCH_LOADING: MonetateExperiment = {
  experiment: 'EoS_searchLoading',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showLoading: 'showLoading',
  },
};

export const DEFER_JS_ASSETS: MonetateExperiment = {
  experiment: 'build_deferJsAssets',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    defer: 'defer',
  },
};

export const EOS_FAVOURITES_PAGE_LOADING: MonetateExperiment = {
  experiment: 'EoS_FavouritesPageLoding',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    favouritesLoading: 'favouritesLoading',
  },
};

export const TROLLEY_RECOMMENDATIONS: MonetateExperiment = {
  experiment: 'trolley_recommendations',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    displayRecommendations: 'displayRecommendations',
  },
};

// Register here the experiments
const allExperiments = new Map([
  [URGENCY_BANNER.experiment, URGENCY_BANNER],
  [BOOST_CATS.experiment, BOOST_CATS],
  [NAV_LINKS_TRENDING.experiment, NAV_LINKS_TRENDING],
  [SHOW_SIMILAR.experiment, SHOW_SIMILAR],
  [SHOW_PRODUCT_TAGS.experiment, SHOW_PRODUCT_TAGS],
  [SHOW_MARKETING_BADGES.experiment, SHOW_MARKETING_BADGES],
  [SPONSORED_FAVOURITES.experiment, SPONSORED_FAVOURITES],
  [CHECKOUT_SAVE_CARD_COPY.experiment, CHECKOUT_SAVE_CARD_COPY],
  [POD_SLIDEDOWN_ADVERT_RECIPES.experiment, POD_SLIDEDOWN_ADVERT_RECIPES],
  [POD_SLIDEDOWN_ADVERT_RELATED.experiment, POD_SLIDEDOWN_ADVERT_RELATED],
  [POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH.experiment, POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH],
  [POD_SLIDEDOWN_MULTIBUY_NUDGE.experiment, POD_SLIDEDOWN_MULTIBUY_NUDGE],
  [PDP_RECOMMENDATIONS.experiment, PDP_RECOMMENDATIONS],
  [POD_VIEW_ALTERNATIVES.experiment, POD_VIEW_ALTERNATIVES],
  [POD_WAS_PRICE.experiment, POD_WAS_PRICE],
  [TROLLEY_PROMO_BADGE.experiment, TROLLEY_PROMO_BADGE],
  [SWAP_UNAVAILABLE_TROLLEY_ITEMS.experiment, SWAP_UNAVAILABLE_TROLLEY_ITEMS],
  [EASTER_MODAL_EXPERIMENT.experiment, EASTER_MODAL_EXPERIMENT],
  [HOME_PAGE_HEADER.experiment, HOME_PAGE_HEADER],
  [CATEGORY_NAVIGATION.experiment, CATEGORY_NAVIGATION],
  [EOS_MULTIPLE_IMAGERY.experiment, EOS_MULTIPLE_IMAGERY],
  [EOS_HERO_IMAGERY.experiment, EOS_HERO_IMAGERY],
  [SERVICE_SELECTION_COLLECTION_ALTERNATIVE.experiment, SERVICE_SELECTION_COLLECTION_ALTERNATIVE],
  [BUILD_OFFER_DETAILS_AEM_IMAGES.experiment, BUILD_OFFER_DETAILS_AEM_IMAGES],
  [BUILD_VIEW_ALTERNATIVES.experiment, BUILD_VIEW_ALTERNATIVES],
  [SHOW_MYJL_REWARDS.experiment, SHOW_MYJL_REWARDS],
  [DEFER_JS_ASSETS.experiment, DEFER_JS_ASSETS],
  [EOS_SEARCH_LOADING.experiment, EOS_SEARCH_LOADING],
  [EOS_FAVOURITES_PAGE_LOADING.experiment, EOS_FAVOURITES_PAGE_LOADING],
  [TROLLEY_RECOMMENDATIONS.experiment, TROLLEY_RECOMMENDATIONS],
]);

const monetateExperimentIds = [...allExperiments.keys()];

export const getMonetateExperimentIds = () => monetateExperimentIds;
export const getMonetateExperimentById = (id: string) => allExperiments.get(id);
export const matchDecisionByExperiment = (id: string, variantDecision: string) =>
  id
    ? getMonetateExperimentById(id)?.variant[variantDecision] || MONETATE_DEFAULT_VARIANT
    : MONETATE_DEFAULT_VARIANT;
