/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import { useSpring, useTransition, a } from 'react-spring';
import { MY_DETAILS, SLOT_BUTTON, SEASONAL } from 'constants/categoryIds';
import { KEY_ENTER, KEY_ESCAPE } from 'constants/keys';
import { MIDDLE_MEGA_MENU, SEASONAL_MENU } from 'constants/experience-fragments';
import { cmsMenuLinksType } from 'constants/types/cms-menu';
import NavigationProvider from 'components/MegaMenu/NavigationProvider';
import usePrevious from 'hooks/use-previous';
import { singleResizeWatcher } from 'utils/single-event-watcher';
import ExperienceFragment from 'components/ExperienceFragment';
import { getSeasonalMenuExperienceFragment } from 'redux/modules/experience-fragments/selectors/get-seasonal-menu-experience-fragment';
import ExternalLinkIcon from '@johnlewispartnership/wtr-ingredients/dist/Icon/ExternalLink';
import resetMegaMenu from 'redux/modules/mega-menu/actions/reset-mega-menu';
import FocusTrap from 'components/FocusTrap';
import {
  Burger,
  ChevronLeft,
  Close,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import SlotButtonText from 'components/SiteHeader/SlotButton/SlotButtonText';
import MenuExperienceFragmentClickHandler from 'components/ExperienceFragment/MenuExperienceFragmentClickHandler';
import MenuLink from './MenuLink';
import MenuCard from './MenuCard';

import styles from './SlideOutNav.scss';
import seasonalStyles from '../SeasonalMenu/SeasonalMenuPanels/SeasonalMenuPanels.scss';

const SlideOutNav = ({
  closeSubcategory,
  showContactAddressNotPresentNotification,
  level,
  menus,
  navigateFromMegaMenu,
  previousTitle,
  openSubcategory,
  specialistShopLinks,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [hasBeenOpenBefore, setHasBeenOpenBefore] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      dispatch(resetMegaMenu());
    }
  }, [isOpen]);

  useEffect(() => {
    if (!hasBeenOpenBefore && isOpen) {
      setHasBeenOpenBefore(true);
    }
  }, [hasBeenOpenBefore, isOpen]);

  const animationConfig = {
    duration: 200,
    easing: t => t * t * t, // ease in cubic
  };

  const renderNavSubTitleComponent = txtStyle =>
    txtStyle === 'small' && (
      <span className={styles.smallWrapper}>
        <ExternalLinkIcon />
        <span className={styles.navSubTitle}>Each website opens in a new tab.</span>
      </span>
    );

  const renderSpecialistLinksHeading = txtStyle =>
    txtStyle === 'heading' && <span className={styles.heading}>MORE FROM WAITROSE</span>;

  const buttonRef = useRef();
  const menuRef = useRef();
  const navigationRef = useRef();
  const maxMenus = 3;

  const { seasonalMenuContent, seasonalMenuTitle } = useSelector(getSeasonalMenuExperienceFragment);

  const showContentEditableSeasonalMenu = !!seasonalMenuContent && !!seasonalMenuTitle;

  const [deeper, setDeeper] = useState(true);
  const [trapFocus, setTrapFocus] = useState(false);
  const closing = usePrevious(isOpen) && !isOpen;
  const isSeasonalLevel =
    level === 1 && menus[0]?.id === SEASONAL && showContentEditableSeasonalMenu;
  const isCmsLevel = level === 1 && !isSeasonalLevel;

  const menuTransition = useTransition([menus], {
    config: animationConfig,
    enter: { transform: 'translateX(0%)' },
    from: { position: 'absolute', transform: deeper ? 'translateX(100%)' : 'translateX(-100%)' },
    key: level,
    leave: closing
      ? {
          // prevent current menu sliding away and root menu sliding in while nav is closing
          transform: 'translateX(0)',
          zIndex: 12,
        }
      : { transform: deeper ? 'translateX(-100%)' : 'translateX(100%)' },
  });

  const navSpring = useSpring({
    config: animationConfig,
    from: { display: 'none' },
    to: isOpen
      ? [{ display: 'block' }, { transform: 'translateX(0%)' }]
      : [{ display: 'block', transform: 'translateX(-100%)' }, { display: 'none' }],
  });

  const overlaySpring = useSpring({
    config: animationConfig,
    from: { display: 'none' },
    to: isOpen
      ? [{ display: 'block' }, { opacity: 1 }]
      : [{ display: 'block', opacity: 0 }, { display: 'none' }],
  });

  const focusFirst = useCallback(() => {
    setTimeout(() => navigationRef.current?.focusFirst(), animationConfig.duration);
  }, [animationConfig, navigationRef]);

  const focusId = useCallback(
    id => {
      setTimeout(() => navigationRef.current?.focusId(id), animationConfig.duration);
    },
    [animationConfig, navigationRef],
  );

  const scrollMenuToTop = useCallback(() => {
    setTimeout(() => {
      if (menuRef.current) menuRef.current.scrollTop = 0;
    }, 0);
  }, [menuRef]);

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(menuRef.current);
      scrollMenuToTop();
    }
    // this delay is necessary to avoid the scroll bug on mobile iOS
    // looks like because of the animated menu the <FocusTrap> fails
    // because there are no focused elements
    setTimeout(() => {
      setTrapFocus(isOpen);
    }, 150);
    return clearAllBodyScrollLocks;
  }, [isOpen, scrollMenuToTop]);

  const nextLevel = useCallback(
    (itemId, focus = true) => {
      openSubcategory(itemId);
      if (level < maxMenus) {
        setDeeper(true);
        scrollMenuToTop();
        if (focus) focusFirst();
      }
    },
    [focusFirst, level, openSubcategory, scrollMenuToTop],
  );

  const previousLevel = useCallback(() => {
    if (level > 0) {
      setDeeper(false);
      closeSubcategory();
      scrollMenuToTop();
      focusId(menus[0].id);
    }
  }, [focusId, closeSubcategory, level, menus, scrollMenuToTop, setDeeper]);

  const handleClick = useCallback(
    itemId => () => {
      nextLevel(itemId, false);
    },
    [nextLevel],
  );

  const handleMenuButtonKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_ENTER) focusFirst();
    },
    [focusFirst],
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_ESCAPE) {
        toggleMenu();
        event.preventDefault();
        event.stopPropagation();
      }
      if (event.keyCode === KEY_ENTER) focusFirst();
    },
    [toggleMenu, focusFirst],
  );

  const handleClickBack = useCallback(() => {
    setDeeper(false);
    closeSubcategory();
  }, [closeSubcategory, setDeeper]);

  useEffect(() => {
    const width = window.innerWidth;
    if (isOpen)
      // eslint-disable-next-line consistent-return
      singleResizeWatcher(() => {
        const newWidth = window.innerWidth;
        if (width !== newWidth) {
          return toggleMenu();
        }
      });
  }, [isOpen, toggleMenu]);

  const renderSpecialistShopLinks = useCallback(
    () =>
      specialistShopLinks.map(({ id, card, name, newWindow, style: txtStyle, subtext, href }) => (
        <Fragment key={id}>
          {!card && (
            <>
              {renderSpecialistLinksHeading(name, txtStyle)}
              {renderNavSubTitleComponent(name, txtStyle)}
            </>
          )}
          {card && (
            <MenuCard
              id={id}
              level={level}
              menus={menus}
              name={name}
              description={subtext}
              onClick={() => {
                navigateFromMegaMenu(level, name, id);
              }}
              path={href}
              newWindow={newWindow}
              toggleMobileMenu={toggleMenu}
            />
          )}
        </Fragment>
      )),
    [
      level,
      navigateFromMegaMenu,
      renderNavSubTitleComponent,
      renderSpecialistLinksHeading,
      specialistShopLinks,
    ],
  );

  const animatedMenus = menuTransition((style, item) => (
    <a.div
      style={style}
      className={classNames(styles.menuLevel, {
        [styles.cmsLevel]: isCmsLevel,
      })}
    >
      <NavigationProvider onLeftKey={previousLevel} onRightKey={nextLevel} ref={navigationRef}>
        {item &&
          item.map(({ categoryIds = [], id, name }) => (
            <Fragment key={id}>
              <h4 className={styles.navTitle}>{isSeasonalLevel ? seasonalMenuTitle : name}</h4>
              {categoryIds.map(categoryId => {
                let linkProps = {
                  id: categoryId,
                  maxMenus,
                };
                if (typeof categoryId === 'object') {
                  linkProps = {
                    ...linkProps,
                    ...categoryId,
                  };
                }
                if (isSeasonalLevel && level === 1) {
                  return null;
                }
                if (!showContentEditableSeasonalMenu && categoryId === SEASONAL && level === 0) {
                  return null;
                }
                return (
                  <MenuLink
                    {...linkProps}
                    key={linkProps.id}
                    showWarningIcon={
                      categoryId === MY_DETAILS && showContactAddressNotPresentNotification
                    }
                    onClick={handleClick(linkProps.id)}
                    toggleMobileMenu={toggleMenu}
                  >
                    {categoryId === SLOT_BUTTON && <SlotButtonText showEndTime />}
                  </MenuLink>
                );
              })}
            </Fragment>
          ))}
        {isCmsLevel && (
          <div className={styles.middleMenu}>
            <MenuExperienceFragmentClickHandler
              trackingPrefix="MiddleMegaMenu"
              onClose={toggleMenu}
            >
              <ExperienceFragment
                experienceFragmentKey={MIDDLE_MEGA_MENU.key}
                locationName={MIDDLE_MEGA_MENU.location}
              />
            </MenuExperienceFragmentClickHandler>
            {renderSpecialistShopLinks()}
          </div>
        )}
        {isSeasonalLevel && (
          <div
            className={classNames(
              seasonalStyles.nav,
              seasonalStyles.panelWrapper,
              seasonalStyles.open,
            )}
          >
            <MenuExperienceFragmentClickHandler trackingPrefix="Seasonal" onClose={toggleMenu}>
              <ExperienceFragment
                experienceFragmentKey={SEASONAL_MENU.key}
                locationName={SEASONAL_MENU.location}
              />
            </MenuExperienceFragmentClickHandler>
          </div>
        )}
        {level === 0 && <div className={styles.specialistShops}>{renderSpecialistShopLinks()}</div>}
      </NavigationProvider>
    </a.div>
  ));

  const BackButton = () => (
    <button
      className={classNames(styles.iconButton, styles.backButton)}
      onClick={handleClickBack}
      data-testid="back-button"
      type="button"
    >
      <span className="sr-only">Back up a level</span>
      <ChevronLeft />
      {previousTitle}
    </button>
  );

  const CloseButton = () => (
    <div className={styles.closeWrapper}>
      <button
        className={styles.closeButton}
        data-testid="close-button"
        onClick={toggleMenu}
        type="button"
      >
        <span className="sr-only">Close</span>
        <Close size="small" />
      </button>
    </div>
  );

  const menuButton = (
    <button
      aria-controls="slide-out-navigation"
      aria-expanded={isOpen}
      aria-label="Toggle shop menu"
      className={classNames(
        styles.menuButton,
        'visible-xs-block visible-sm-block visible-md-block',
        {
          [styles.hide]: isOpen,
          [styles.CANotPresent]: showContactAddressNotPresentNotification,
        },
      )}
      data-testid="small-screen-menu-button"
      onClick={toggleMenu}
      onKeyDown={handleMenuButtonKeyDown}
      ref={buttonRef}
      type="button"
    >
      <Burger data-testid="BurgerIcon" />
    </button>
  );

  return (
    <FocusTrap active={trapFocus}>
      <div
        className={styles.menuWrapper}
        data-testid="small-screen-menu-wrapper"
        onKeyDown={handleKeyDown}
        role="presentation"
      >
        {menuButton}
        <a.div
          data-testid="small-screen-menu"
          id="slide-out-navigation"
          ref={menuRef}
          className={styles.menu}
          style={hasBeenOpenBefore ? navSpring : { display: 'none' }}
        >
          <div className={styles.topWrapper}>
            <CloseButton />
            {level > 0 && <BackButton />}
          </div>
          {animatedMenus}
        </a.div>
        <a.div
          className={styles.menuOverlay}
          data-testid="small-screen-menu-overlay"
          onClick={toggleMenu}
          role="presentation"
          style={hasBeenOpenBefore ? overlaySpring : { display: 'none' }}
        />
      </div>
    </FocusTrap>
  );
};

SlideOutNav.propTypes = {
  toggleMenu: func.isRequired,
  closeSubcategory: func.isRequired,
  showContactAddressNotPresentNotification: bool,
  level: number,
  menus: arrayOf(
    shape({
      id: string,
      name: string,
      categoryIds: arrayOf(oneOfType([shape({ id: string }), string])),
    }),
  ),
  navigateFromMegaMenu: func.isRequired,
  openMegaMenu: func.isRequired,
  openSubcategory: func.isRequired,
  previousTitle: string,
  specialistShopLinks: cmsMenuLinksType,
  showMenuButton: bool,
};

SlideOutNav.defaultProps = {
  showContactAddressNotPresentNotification: false,
  level: 0,
  menus: [],
  previousTitle: undefined,
  specialistShopLinks: [],
  showMenuButton: false,
};

export default SlideOutNav;
